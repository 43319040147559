import React from "react";
import "./ProductGuarantee.css";

const SpeedProductGuarantee = () => {
    return (
        <div className="speed-product-guarantee">
            <div className="guarantee-item">
                <p>Same Day Shipping on orders before 3 pm EST</p>
            </div>
            <div className="guarantee-item">
                <p>Money-back guarantee, 100% Satisfaction Guaranteed</p>
            </div>
            <div className="guarantee-item">
                <p>Various Payment Options</p>
            </div>
        </div>
    );
};

export default SpeedProductGuarantee;
