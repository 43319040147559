// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.speed-product-guarantee {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
    background-color: #f9f9f9;
    margin-top: 20px;
}

.guarantee-item {
    text-align: center;
    flex: 1 1;
    padding: 0 10px;
}

.guarantee-item img {
    max-width: 50px;
    margin-bottom: 10px;
}

.guarantee-item p {
    font-size: 0.9rem;
    margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/shopify/product/guarantee/ProductGuarantee.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,WAAW;IACX,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,SAAO;IACP,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,SAAS;AACb","sourcesContent":[".speed-product-guarantee {\n    display: flex;\n    justify-content: space-between;\n    padding: 20px;\n    width: 100%;\n    background-color: #f9f9f9;\n    margin-top: 20px;\n}\n\n.guarantee-item {\n    text-align: center;\n    flex: 1;\n    padding: 0 10px;\n}\n\n.guarantee-item img {\n    max-width: 50px;\n    margin-bottom: 10px;\n}\n\n.guarantee-item p {\n    font-size: 0.9rem;\n    margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
