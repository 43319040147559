import React, { useState } from "react";
import "./ProductFaq.css";

const SpeedProductFAQ = () => {
    const [selectedQuestion, setSelectedQuestion] = useState(null);

    const toggleQuestion = (question) => {
        setSelectedQuestion(selectedQuestion === question ? null : question);
    };

    return (
        <div className="speed-product-faq">
            <h2>Frequently Asked Questions</h2>
            <p>Need help finding an answer to your question? Ask our customer support team.</p>
            <div className="faq-item">
                <button onClick={() => toggleQuestion(1)}>
                    Do Speed Swabs come with Digital Labels?
                </button>
                {selectedQuestion === 1 && (
                    <p>No, the DRT or Digital Rapid Test labels are extra...</p>
                )}
            </div>
            <div className="faq-item">
                <button onClick={() => toggleQuestion(2)}>
                    Question #2
                </button>
                {selectedQuestion === 2 && (
                    <p>Answer for question 2...</p>
                )}
            </div>
            <div className="faq-item">
                <button onClick={() => toggleQuestion(3)}>
                    Question #3
                </button>
                {selectedQuestion === 3 && (
                    <p>Answer for question 3...</p>
                )}
            </div>
            <div className="faq-item">
                <button onClick={() => toggleQuestion(4)}>
                    Question #4
                </button>
                {selectedQuestion === 4 && (
                    <p>Answer for question 4...</p>
                )}
            </div>
        </div>
    );
};

export default SpeedProductFAQ;
