// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.read-tile-main-body{

    min-width: 180px;
    max-width: 180px;
    height: 65px;
    margin-left: 15px;
    margin-top: 11px;
    margin-bottm: 11px;
    border-radius: 3px;
    box-shadow: 0px 3px 6px #00000029;
    overflow-y: auto; /* add scrolling */
    -ms-overflow-style: none; /* Internet Explorer 10+ remove scroll bar */
    scrollbar-width: none; /* Firefox remove scroll bar */
}

.read-tile-text-frame{
    font-size: 12px;
    padding: 10px;
    margin-top: 0;
}

.read-tile-scan-info-text{
    font-size: 12px;
    text-align: left;
    margin-bottom: 0;
    margin-top: 5px;
}
.read-tile-info-frame{
    color: #58585A;
}
`, "",{"version":3,"sources":["webpack://./src/components/footers/admin_footer/read_tiles/read_tile/ReadTile.css"],"names":[],"mappings":"AAAA;;IAEI,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,iCAAiC;IACjC,gBAAgB,EAAE,kBAAkB;IACpC,wBAAwB,EAAE,4CAA4C;IACtE,qBAAqB,EAAE,8BAA8B;AACzD;;AAEA;IACI,eAAe;IACf,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,cAAc;AAClB","sourcesContent":[".read-tile-main-body{\n\n    min-width: 180px;\n    max-width: 180px;\n    height: 65px;\n    margin-left: 15px;\n    margin-top: 11px;\n    margin-bottm: 11px;\n    border-radius: 3px;\n    box-shadow: 0px 3px 6px #00000029;\n    overflow-y: auto; /* add scrolling */\n    -ms-overflow-style: none; /* Internet Explorer 10+ remove scroll bar */\n    scrollbar-width: none; /* Firefox remove scroll bar */\n}\n\n.read-tile-text-frame{\n    font-size: 12px;\n    padding: 10px;\n    margin-top: 0;\n}\n\n.read-tile-scan-info-text{\n    font-size: 12px;\n    text-align: left;\n    margin-bottom: 0;\n    margin-top: 5px;\n}\n.read-tile-info-frame{\n    color: #58585A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
