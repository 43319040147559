// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CookieConsentBanner.css */
.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #706F70;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
}

.cookie-consent-text {
    font-size: 1rem;
    text-align: left;
    padding-left: 60px;
    max-width: 95%;
    color: #ffffff;
}

.cookie-consent-button {
    color: #ffffff;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
}

.cookie-consent-button-container {
    width: 20%;
}

.cookie-consent-button:hover {
    background-color: #2F80ED;
}


.cookie-consent-arrow {
    width: 7%;
}

@media (max-width: 768px) {
    .cookie-consent-banner {
        flex-direction: column;
        align-items: flex-start;
    }

    .cookie-consent-text {
        margin-bottom: 10px;
        max-width: 100%;
    }

    .cookie-consent-button {
        width: 20%;
        text-align: center;
        margin-left: 100px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/modals/cookie_consent/SpeedSwabsCookieConsentBanner.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;IACI,eAAe;IACf,SAAS;IACT,OAAO;IACP,QAAQ;IACR,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,yBAAyB;AAC7B;;;AAGA;IACI,SAAS;AACb;;AAEA;IACI;QACI,sBAAsB;QACtB,uBAAuB;IAC3B;;IAEA;QACI,mBAAmB;QACnB,eAAe;IACnB;;IAEA;QACI,UAAU;QACV,kBAAkB;QAClB,kBAAkB;IACtB;AACJ","sourcesContent":["/* CookieConsentBanner.css */\n.cookie-consent-banner {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    background-color: #706F70;\n    color: #ffffff;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    z-index: 1000;\n}\n\n.cookie-consent-text {\n    font-size: 1rem;\n    text-align: left;\n    padding-left: 60px;\n    max-width: 95%;\n    color: #ffffff;\n}\n\n.cookie-consent-button {\n    color: #ffffff;\n    border-radius: 5px;\n    font-size: 1rem;\n    cursor: pointer;\n}\n\n.cookie-consent-button-container {\n    width: 20%;\n}\n\n.cookie-consent-button:hover {\n    background-color: #2F80ED;\n}\n\n\n.cookie-consent-arrow {\n    width: 7%;\n}\n\n@media (max-width: 768px) {\n    .cookie-consent-banner {\n        flex-direction: column;\n        align-items: flex-start;\n    }\n\n    .cookie-consent-text {\n        margin-bottom: 10px;\n        max-width: 100%;\n    }\n\n    .cookie-consent-button {\n        width: 20%;\n        text-align: center;\n        margin-left: 100px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
