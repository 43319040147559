// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shopify-cart-footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
}

.cart-total {
    flex-grow: 1;
}

.total-text {
    font-size: 14px;
    color: #333;
}

.total-amount {
    font-size: 24px;
    font-weight: bold;
}

.shopify-cart-footer-buttons {
    display: flex;
    gap: 50%;
}

.checkout-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 12px 20px;
}

.continue-shopping-button {
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 12px 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/shopify/speed_cart/actions/ShopifySpeedCartActions.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,QAAQ;AACZ;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".shopify-cart-footer {\n    margin-top: 20px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 40px;\n}\n\n.cart-total {\n    flex-grow: 1;\n}\n\n.total-text {\n    font-size: 14px;\n    color: #333;\n}\n\n.total-amount {\n    font-size: 24px;\n    font-weight: bold;\n}\n\n.shopify-cart-footer-buttons {\n    display: flex;\n    gap: 50%;\n}\n\n.checkout-button {\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 30px;\n    padding: 12px 20px;\n}\n\n.continue-shopping-button {\n    background-color: #6c757d;\n    color: white;\n    border: none;\n    border-radius: 30px;\n    padding: 12px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
