import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import './QuestOrdersModal.css';
import {toggleQuestOrderDetailModal} from "../../../store/orders/questOrderSlice";

const QuestOrderModal = () => {
    const dispatch = useDispatch();
    const isQuestOrderDetailsVisible = useSelector(state => state.questOrders.isQuestOrderDetailsVisible);
    const selectedOrganization = useSelector(state => state.questOrders.selectedOrganization);

    const [selectedAddress, setSelectedAddress] = useState(null);
    const [panelCode, setPanelCode] = useState(null);

    const questOrder = {
        "order_quest_supplies": {
            "items": [
                {
                    "item_name": "DOT 5 Part Form",
                    "quantity": 1,
                }
            ],
            "ship_to_address": {
                "attention": "All Start Trucking",
                "line_one": "916 Court Street",
                "line_two": "",
                "city": "Norfolk",
                "state": "VA",
                "zip": "23704"
            },
            "client_details": {
                "organization_id": 12126,
                "account_number": "11947042",
                "account_name": "All Start Trucking"
            },
            "user_login": {
                "username": "testuser",
                "password": "securepassword"
            }
        }
    };
    


    if (!isQuestOrderDetailsVisible) return null;

    const handleCloseModal = () => {
        dispatch(toggleQuestOrderDetailModal());
    };

    return (
        <div className="quest-order-modal-overlay">
            <div className="quest-order-modal">
                <button className="close-button" onClick={handleCloseModal}>X</button>
                <h2>Quest Order Details</h2>

                <div className="organization-details">
                    <p><strong>Organization Name:</strong> {selectedOrganization?.organization_name}</p>
                    <p><strong>Account Number:</strong> {selectedOrganization?.organization_sub_account_number}</p>
                    <p><strong>Address:</strong> {selectedOrganization?.address}</p>
                </div>

                <div className="address-selection">
                    <label>Select Address:</label>
                    <Select
                        options={null}
                        value={selectedAddress}
                        onChange={setSelectedAddress}
                        placeholder="Choose an address"
                    />
                </div>

                <div className="panel-code-selection">
                    <label>Select Panel Code:</label>
                    <Select
                        options={null}
                        value={panelCode}
                        onChange={setPanelCode}
                        placeholder="Choose a panel code"
                    />
                </div>
            </div>
        </div>
    );
};

export default QuestOrderModal;
