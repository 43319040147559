// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Header Container */
.public-freddy-header {
    background-color: #F0F0F0; /* Match the Shopify background */
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

/* Container for all header content */
.public-freddy-header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/* Left Section (Logo) */
.public-freddy-header__left {
    display: flex;
    align-items: center;
}

.public-freddy-header__logo-link {
    display: inline-block;
}

.public-freddy-header__logo {
    height: 50px; /* Match the height from the Shopify header */
    width: auto;
}

/* Right Section (Icons) */
.public-freddy-header__right {
    display: flex;
    align-items: center;
    gap: 15px; /* Slight space between icons */
}

.public-freddy-header__icon-link {
    display: flex;
    align-items: center;
}

.public-freddy-header__icon-link:hover {
    cursor: pointer;
}

/* Icons inside the right section */
.nav-icon {
    width: 30px;
    height: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/headers/public_freddy_header/PublicFreddyHeader.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,yBAAyB,EAAE,iCAAiC;IAC5D,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA,qCAAqC;AACrC;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf;;AAEA,wBAAwB;AACxB;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,YAAY,EAAE,6CAA6C;IAC3D,WAAW;AACf;;AAEA,0BAA0B;AAC1B;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS,EAAE,+BAA+B;AAC9C;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA,mCAAmC;AACnC;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":["/* Header Container */\n.public-freddy-header {\n    background-color: #F0F0F0; /* Match the Shopify background */\n    display: flex;\n    width: 100%;\n    align-items: center;\n    justify-content: space-between;\n}\n\n/* Container for all header content */\n.public-freddy-header__container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n}\n\n/* Left Section (Logo) */\n.public-freddy-header__left {\n    display: flex;\n    align-items: center;\n}\n\n.public-freddy-header__logo-link {\n    display: inline-block;\n}\n\n.public-freddy-header__logo {\n    height: 50px; /* Match the height from the Shopify header */\n    width: auto;\n}\n\n/* Right Section (Icons) */\n.public-freddy-header__right {\n    display: flex;\n    align-items: center;\n    gap: 15px; /* Slight space between icons */\n}\n\n.public-freddy-header__icon-link {\n    display: flex;\n    align-items: center;\n}\n\n.public-freddy-header__icon-link:hover {\n    cursor: pointer;\n}\n\n/* Icons inside the right section */\n.nav-icon {\n    width: 30px;\n    height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
