// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.speed-product-details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 40px 20px;
    margin: 0 auto;
    flex-wrap: wrap;
}

.product-info {
    flex: 1 1;
    padding-right: 40px;
    max-width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/shopify/product/details/ProductDetails.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,uBAAuB;IACvB,WAAW;IACX,kBAAkB;IAClB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,SAAO;IACP,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":[".speed-product-details {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-start;\n    width: 100%;\n    padding: 40px 20px;\n    margin: 0 auto;\n    flex-wrap: wrap;\n}\n\n.product-info {\n    flex: 1;\n    padding-right: 40px;\n    max-width: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
