// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HeaderRow.css */

.organizations-table-header-row {
    background-color: #f7f9fc;
    color: #333;
    font-weight: bold;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.organizations-table-header-cell {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}
`, "",{"version":3,"sources":["webpack://./src/components/clients/organizations/header_row/HeaderRow.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,6BAA6B;IAC7B,0BAA0B;AAC9B","sourcesContent":["/* HeaderRow.css */\n\n.organizations-table-header-row {\n    background-color: #f7f9fc;\n    color: #333;\n    font-weight: bold;\n    text-align: left;\n    display: flex;\n    justify-content: space-between;\n}\n\n.organizations-table-header-cell {\n    padding: 12px 15px;\n    border-bottom: 1px solid #ddd;\n    border-top: 1px solid #ddd;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
