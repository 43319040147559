import React, { useEffect, useState } from 'react';
import './OrganizationsTable.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchShopifyOrganizationsByClientId
} from "../../../../store/autobot/clients/organizations/organizationsSlice";
import LoadingSpinningCup from "../../../../pages/loading/Loading";
import HeaderRow from "../header_row/HeaderRow";
import OrganizationRow from "../organizations_row/OrganizationsRow";

const OrganizationsTable = ({ clientId, userRoles }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const organizationsData = useSelector(state => state.organizations.organizationData) || [];

    useEffect(() => {
        if (clientId) { // Check if clientId is present
            const fetchData = async () => {
                setIsLoading(true);
                await dispatch(fetchShopifyOrganizationsByClientId({ selectedClientAccountId: clientId }));
                setIsLoading(false);
            };

            fetchData();
        }
    }, [dispatch, clientId]);

    return (
        <div className="organization-table-container">
            {
                organizationsData ?
                    <div>
                        <HeaderRow/>
                        <div className="organization-table-scroll-container">
                        {organizationsData.map((organization) => (
                            <OrganizationRow key={`${organization.organization_id}-${clientId}`}
                                             organization={organization} userRoles={userRoles}/>
                        ))}
                        </div>
                    </div>
                    : <LoadingSpinningCup/>
            }
        </div>
    );
};

export default OrganizationsTable;
