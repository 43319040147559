// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-container {
    /* Styles for the navbar container */
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #f0f0f0; /* Example background color */
    padding: 10px;
}

.navbar-container div {
    /* Styles for each navbar item */
    cursor: pointer;
}

.selectedIconTab {
    /* Styles for the selected/active icon */
    color: #007bff; /* Example active color - blue */
    border-bottom: 2px solid #007bff; /* Example bottom border */
}

/* You might have specific styles for each icon, for instance: */
.icon-specimen, .icon-laboratory, .icon-person, .icon-upload, .icon-bell {
    /* Common styles for all icons */
    fill: #333; /* Default icon color */
    width: 30px; /* Adjust as necessary */
    height: 30px; /* Adjust as necessary */
}

/* When an icon is active/selected */
.selectedIconTab .icon-specimen,
.selectedIconTab .icon-laboratory,
.selectedIconTab .icon-person,
.selectedIconTab .icon-upload,
.selectedIconTab .icon-bell {
    fill: #007bff; /* Active icon color - blue */
}
`, "",{"version":3,"sources":["webpack://./src/components/headers/admin_header/nav/Nav.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,yBAAyB,EAAE,6BAA6B;IACxD,aAAa;AACjB;;AAEA;IACI,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,wCAAwC;IACxC,cAAc,EAAE,gCAAgC;IAChD,gCAAgC,EAAE,0BAA0B;AAChE;;AAEA,gEAAgE;AAChE;IACI,gCAAgC;IAChC,UAAU,EAAE,uBAAuB;IACnC,WAAW,EAAE,wBAAwB;IACrC,YAAY,EAAE,wBAAwB;AAC1C;;AAEA,oCAAoC;AACpC;;;;;IAKI,aAAa,EAAE,6BAA6B;AAChD","sourcesContent":[".navbar-container {\n    /* Styles for the navbar container */\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    background-color: #f0f0f0; /* Example background color */\n    padding: 10px;\n}\n\n.navbar-container div {\n    /* Styles for each navbar item */\n    cursor: pointer;\n}\n\n.selectedIconTab {\n    /* Styles for the selected/active icon */\n    color: #007bff; /* Example active color - blue */\n    border-bottom: 2px solid #007bff; /* Example bottom border */\n}\n\n/* You might have specific styles for each icon, for instance: */\n.icon-specimen, .icon-laboratory, .icon-person, .icon-upload, .icon-bell {\n    /* Common styles for all icons */\n    fill: #333; /* Default icon color */\n    width: 30px; /* Adjust as necessary */\n    height: 30px; /* Adjust as necessary */\n}\n\n/* When an icon is active/selected */\n.selectedIconTab .icon-specimen,\n.selectedIconTab .icon-laboratory,\n.selectedIconTab .icon-person,\n.selectedIconTab .icon-upload,\n.selectedIconTab .icon-bell {\n    fill: #007bff; /* Active icon color - blue */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
