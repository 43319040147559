// src/routes/UnauthenticatedRoutes.js

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';  // Import routing components from react-router-dom
import PanelsPage from '../pages/panels/Panels';  // Import the Panels page component
import PublicHomePage from '../pages/./home/./public_home/PublicHome';  // Import the public home page
import ShopifyHomePage from "../pages/./home/shopify_home/ShopifyHome";  // Import Shopify home page
import LoginPage from '../pages/user/login/Login';  // Import the login page
import ClientOrdersPage from '../pages/client_orders/ClientOrders';  // Import the client orders page
import ShopifyProtectedRoute from './ShopifyProtectedRoute';  // Import the protected route component for Shopify
import { useSelector } from "react-redux";  // Import the useSelector hook to retrieve Redux state
import ShopifySpeedProductPage from "../pages/shopify/speed/product/ShopifySpeedProductPage";  // Import the Shopify SpeedSwab product page
import PublicVerifyPage from "../pages/user/verify/PublicVerify";
import PublicFreddyLogin from "../components/user/public_freddy_login/PublicFreddyLogin";
import PublicFreddyDashboard from "../pages/dashboards/public_freddy_dashboard/PublicFreddyDashboard";
import FreddyPublicUserManagementPage from "../pages/user/manage/FreddyPublicUserManagementPage";
import FreddyPublicLocationManagementPage from "../pages/locations/manage/FreddyPublicLocationManagementPage";
import OrganizationsPage from "../pages/organizations/OrganizationsPage";  // Import the public user verification page

// Main UnauthenticatedRoutes component definition
const UnauthenticatedRoutes = ({ instance }) => {

    // Retrieve Shopify customer details from the Redux store (used to conditionally render routes)
    const lssCustomerDetails = useSelector((state) => state.shopifyUser.lssCollectorDetails);


    const publicToken = useSelector((state) => state.publicUser?.publicAccessToken);

    return (
        // BrowserRouter component wraps all the routes to handle client-side navigation
        <BrowserRouter>
            <Routes>


                {/* Freddy Dashboard route (shown when the user has a valid token) */}
                {publicToken && (
                    <Route path="/home" element={<PublicFreddyDashboard />} />
                )}

                {/* Internal route for staff/admin login */}
                <Route path="/login" element={<LoginPage instance={instance} />} />

                {/* Public route for verifying public users */}
                <Route path="/verify" element={<PublicVerifyPage instance={instance} />} />

                {/* Protected route: Only Shopify authenticated users can access Shopify home page */}
                <Route
                    path="/shopify/home"
                    element={
                        <ShopifyProtectedRoute>
                            <ShopifyHomePage />
                        </ShopifyProtectedRoute>
                    }
                />

                {/* Conditional route: Only show the Panels page if the user has permission to manage panels */}
                {lssCustomerDetails?.can_manage_panels && (
                    <Route
                        path="/panels"
                        element={
                            <ShopifyProtectedRoute>
                                <PanelsPage />
                            </ShopifyProtectedRoute>
                        }
                    />
                )}

                {/* Conditional route: Only show the Panels page if the user has permission to manage panels */}
                {lssCustomerDetails?.can_manage_screeners && (
                    <Route
                        path="/organizations"
                        element={
                            <ShopifyProtectedRoute>
                                <OrganizationsPage />
                            </ShopifyProtectedRoute>
                        }
                    />
                )}

                {/* Conditional route: Only show the Client Orders page if the user has permission to order cartons */}
                {lssCustomerDetails?.can_order_cartons && (
                    <Route
                        path="/orders"
                        element={
                            <ShopifyProtectedRoute>
                                <ClientOrdersPage />
                            </ShopifyProtectedRoute>
                        }
                    />
                )}

                {/* Public route for Shopify SpeedSwab product page, accessible without authentication */}
                <Route path="/product" element={<ShopifySpeedProductPage />} />

                <Route path="/users" element={<FreddyPublicUserManagementPage />} />

                <Route path="/locations" element={<FreddyPublicLocationManagementPage />} />

                {/* Default route (catch-all) that loads the public home page if no specific path is matched */}
                <Route path="/" element={<PublicHomePage instance={instance} />} />

            </Routes>
        </BrowserRouter>
    );
};

export default UnauthenticatedRoutes;  // Export the UnauthenticatedRoutes component for use in other parts of the app
