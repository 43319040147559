// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/product_prices/prices_row/PricesRow.css */

.product-price-row {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #f9f9f9;
}

.product-price-item {
    flex: 1 1;
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.product-name {
    flex: 2 1;  /* Allow product name to take more space */
}

.product-label {
    margin-right: 10px;
    font-weight: bold;
}

.product-name-value {
    padding: 6px 10px;
    background-color: #eaeaea;
    border-radius: 4px;
}

.product-price-input {
    padding: 6px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100px;
    transition: all 0.3s ease;
}

.product-price-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/product_pricing/pricing_row/PricesRow.css"],"names":[],"mappings":"AAAA,2DAA2D;;AAE3D;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,cAAc;IACd,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,SAAO;IACP,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,SAAO,GAAG,0CAA0C;AACxD;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;IACrB,0CAA0C;IAC1C,aAAa;AACjB","sourcesContent":["/* src/components/product_prices/prices_row/PricesRow.css */\n\n.product-price-row {\n    display: flex;\n    align-items: center;\n    padding: 10px;\n    margin: 10px 0;\n    border: 1px solid #ddd;\n    border-radius: 6px;\n    background-color: #f9f9f9;\n}\n\n.product-price-item {\n    flex: 1;\n    display: flex;\n    align-items: center;\n    margin-right: 15px;\n}\n\n.product-name {\n    flex: 2;  /* Allow product name to take more space */\n}\n\n.product-label {\n    margin-right: 10px;\n    font-weight: bold;\n}\n\n.product-name-value {\n    padding: 6px 10px;\n    background-color: #eaeaea;\n    border-radius: 4px;\n}\n\n.product-price-input {\n    padding: 6px;\n    font-size: 1rem;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    width: 100px;\n    transition: all 0.3s ease;\n}\n\n.product-price-input:focus {\n    border-color: #007bff;\n    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
