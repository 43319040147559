// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.speed-swabs-nav-bubble-container {
    position: absolute;
    top: 10%; /* Place it 0.5 inches below the header */
    left: 50%; /* Center it horizontally */
    transform: translateX(-50%);
    z-index: 10;
    transition: all 0.3s ease;
}

.speed-swabs-nav-bubble-container.sticky {
    position: fixed; /* Stick it to the top when scrolling */
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    background-color: rgba(255, 255, 255, 0.9); /* Slightly opaque to match the header */
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow when sticky */
    border-radius: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboards/speed_swab_dashboard/SpeedSwabDashboard.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,QAAQ,EAAE,yCAAyC;IACnD,SAAS,EAAE,2BAA2B;IACtC,2BAA2B;IAC3B,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,eAAe,EAAE,uCAAuC;IACxD,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,WAAW;IACX,0CAA0C,EAAE,wCAAwC;IACpF,aAAa;IACb,yCAAyC,EAAE,8BAA8B;IACzE,gBAAgB;AACpB","sourcesContent":["\n.speed-swabs-nav-bubble-container {\n    position: absolute;\n    top: 10%; /* Place it 0.5 inches below the header */\n    left: 50%; /* Center it horizontally */\n    transform: translateX(-50%);\n    z-index: 10;\n    transition: all 0.3s ease;\n}\n\n.speed-swabs-nav-bubble-container.sticky {\n    position: fixed; /* Stick it to the top when scrolling */\n    top: 10px;\n    left: 50%;\n    transform: translateX(-50%);\n    width: auto;\n    background-color: rgba(255, 255, 255, 0.9); /* Slightly opaque to match the header */\n    padding: 10px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow when sticky */\n    border-radius: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
