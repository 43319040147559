// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.public-freddy-login__container {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}

.public-freddy-login__form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.public-freddy-login__form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
}

.public-freddy-login__form-group input::placeholder {
    color: #aaa;
}

.public-freddy-login__error-message {
    color: red;
    margin-bottom: 15px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/user/public_freddy_login/PublicFreddyLogin.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,wCAAwC;IACxC,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;IACV,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".public-freddy-login__container {\n    background-color: white;\n    padding: 40px;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    max-width: 400px;\n    width: 100%;\n}\n\n.public-freddy-login__form-group {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 15px;\n}\n\n.public-freddy-login__form-group input {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    font-size: 16px;\n}\n\n.public-freddy-login__form-group input::placeholder {\n    color: #aaa;\n}\n\n.public-freddy-login__error-message {\n    color: red;\n    margin-bottom: 15px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
