// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PublicFreddyDashboard.css */

.public-freddy-dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f2f5;
    height: 100vh;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboards/public_freddy_dashboard/PublicFreddyDashboard.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;AACjB","sourcesContent":["/* PublicFreddyDashboard.css */\n\n.public-freddy-dashboard-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    background-color: #f0f2f5;\n    height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
