import React, {useState} from 'react';
import './OrganizationsRow.css';
import ActionTray from "../../../modals/action_tray/ActionTray";
import {ActionTrayIcons} from "../../../../assets/icons";
import {useDispatch} from "react-redux";
import {setOrderSelectedOrganization, toggleQuestOrderDetailModal} from "../../../../store/orders/questOrderSlice";
import {
    setCollectorSelectedOrganization,
    setSelectedOrganization,
    toggleQuestEspManagementModal
} from "../../../../store/quest_esp/questEspSlice";
import {
    setAccountSelectedOrganization,
    toggleQuestAccountManagementModal
} from "../../../../store/laboratory/questAccountRequestSlice";

const OrganizationRow = ({ organization, userRoles }) => {

    const dispatch = useDispatch();

    const handleViewOrderRequestModal = () => {
        dispatch(toggleQuestOrderDetailModal());
        dispatch(setOrderSelectedOrganization(organization))
    };

    const handleManageCollectorsEspAccess = () => {
        dispatch(toggleQuestEspManagementModal());
        dispatch(setCollectorSelectedOrganization(organization));
    };

    const handleRequestAccountCreation = () => {
        dispatch(toggleQuestAccountManagementModal());
        dispatch(setAccountSelectedOrganization(organization))
    };

    const iconsData = [
        {
            icon: ActionTrayIcons.SendIcon,
            alt: "Request Accounts",
            action: handleRequestAccountCreation,
            permission: "can_manage_panels"
        },
        {
            icon: ActionTrayIcons.PersonIcon,
            alt: "Laboratory",
            action: handleManageCollectorsEspAccess,
            permission: "can_manage_screeners"
        },
        {
            icon: ActionTrayIcons.BoxIcon,
            alt: "Order Requests",
            action: handleViewOrderRequestModal,
            permission: "can_order_cartons"
        },
    ];

    return (
        <>
            <div className="organization-table-row">
                <div className="organization-table-cell">{organization.organization_name}</div>
                <div className="organization-table-cell">{organization.organization_sub_account_number}</div>
                <div className="organization-table-cell">{organization.organization_dot_sub_account_number}</div>
                <div className="organization-table-cell">{organization.der_contact}</div>
                <div className="organization-table-cell">{organization.der_telephone}</div>
                <div className="organization-table-cell">{organization.address}</div>
                <div className="organization-table-cell">{organization.city}</div>
                <div className="organization-table-cell">{organization.state}</div>
                <div className="organization-table-cell">{organization.zip}</div>
                <div className="organization-action-tray-table-cell">
                    <ActionTray iconsData={iconsData} userRoles={userRoles} rowData={organization}/>
                </div>
            </div>
        </>
    );
};

export default OrganizationRow;
