// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Navigation.css */
.talk-swabs-button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem; /* Space between the buttons */
}

.talk-swabs-secondary-button,
.talk-swabs-primary-button {
    flex: 1 1;
    max-width: 48%; /* Ensures both buttons are of equal size */
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/forms/assets/navigation/Navigation.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,SAAS,EAAE,8BAA8B;AAC7C;;AAEA;;IAEI,SAAO;IACP,cAAc,EAAE,2CAA2C;IAC3D,kBAAkB;AACtB","sourcesContent":["/* Navigation.css */\n.talk-swabs-button-group {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    gap: 1rem; /* Space between the buttons */\n}\n\n.talk-swabs-secondary-button,\n.talk-swabs-primary-button {\n    flex: 1;\n    max-width: 48%; /* Ensures both buttons are of equal size */\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
