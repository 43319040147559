// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* CSS (PublicVerifyPage.css) */
.public-verify-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f4;
}

.verify-container {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}

.verify-form .form-group {
    margin-bottom: 20px;
}

.verify-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.verify-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.error-message {
    color: red;
    margin-bottom: 15px;
    text-align: center;
}

.loading {
    font-size: 1.5rem;
    color: #007bff;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/user/verify/PublicVerify.css"],"names":[],"mappings":";AACA,+BAA+B;AAC/B;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,kBAAkB;AACtB","sourcesContent":["\n/* CSS (PublicVerifyPage.css) */\n.public-verify-page {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 100vh;\n    background-color: #f4f4f4;\n}\n\n.verify-container {\n    background: #fff;\n    padding: 30px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    max-width: 400px;\n    width: 100%;\n}\n\n.verify-form .form-group {\n    margin-bottom: 20px;\n}\n\n.verify-form label {\n    display: block;\n    margin-bottom: 8px;\n    font-weight: bold;\n}\n\n.verify-form input {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    font-size: 1rem;\n}\n\n.error-message {\n    color: red;\n    margin-bottom: 15px;\n    text-align: center;\n}\n\n.loading {\n    font-size: 1.5rem;\n    color: #007bff;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
