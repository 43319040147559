// authConfig.js
import {config} from "../components/Constants";

export const oauthConfig = {
    shopify: {
        clientId: "ce55d50cae6291a584dd5b8c387c9d93",
        authURL: "https://43ef3f-d0.myshopify.com/admin/oauth/authorize",
        scope: "read_orders,write_customers,read_products,write_products,read_customers,write_customers,write_metaobjects,read_metaobjects,write_draft_orders, write_orders",
        accessType: "per-user",
        redirectUri: `${config.url.REACT_APP_REDIRECT_URL}shopify`,
    },
    ups: {
        clientId: "FzG9MGggkA1pmWJocmIKjB6h9MZBJCknlOBMvd2TNGwExq4W",
        authPageIdentifier: "onlinetools.ups.com/security",
        authURL: "https://wwwcie.ups.com/security/v1/oauth/authorize",
        redirectUri: `${config.url.REACT_APP_REDIRECT_URL}`,
    },
    zoho: {
        clientId: "1000.FCHYNS46251473XYB8P6SBX8VHG3YL",
        authPageIdentifier: "accounts.zoho.com/oauth/v2/auth",
        authURL: "https://accounts.zoho.com/oauth/v2/auth",
        scope: encodeURIComponent("ZohoBooks.invoices.CREATE,ZohoBooks.invoices.READ,ZohoBooks.invoices.UPDATE,ZohoBooks.contacts.READ"),
        accessType: "offline",
        redirectUri: `${config.url.REACT_APP_REDIRECT_URL}zoho/billing`,
    },
    quickbooks: {
        clientId: "AB1sIHAjUQdXiLj0WoooBsPHhqIBXqelG85NQeH3s3Ex8DVukM",
        authPageIdentifier: "appcenter.intuit.com/connect/oauth2",
        authURL: "https://appcenter.intuit.com/connect/oauth2",
        scope: encodeURIComponent("com.intuit.quickbooks.accounting"),
        state: "randomStringOrEncodedData",
        redirectUri: `${config.url.REACT_APP_REDIRECT_URL}quickbooks/billing`,
    },
};
