// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panels-page-container {
    width: 100%;
    min-height: 100vh; /* Ensure the height adjusts properly */
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column; /* Stack header, body, and footer vertically */
}

.panels-header {
    width: 100%;
    padding: 10px 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.panels-header img {
    height: 50px;
}

.panels-body-container {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-grow: 1; /* Make the body take up the remaining space */
}

.panels-content-container {
    width: 100%;
}

.panels-table-container {
    width: 100%;
    height: 100%;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .panels-body-container {
        padding: 15px;
    }

    .panels-header img {
        height: 40px; /* Reduce header logo size */
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/panels/Panels.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB,EAAE,uCAAuC;IAC1D,yBAAyB;IACzB,aAAa;IACb,sBAAsB,EAAE,8CAA8C;AAC1E;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,sBAAsB;IACtB,wCAAwC;IACxC,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,YAAY,EAAE,8CAA8C;AAChE;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA,sCAAsC;AACtC;IACI;QACI,aAAa;IACjB;;IAEA;QACI,YAAY,EAAE,4BAA4B;IAC9C;AACJ","sourcesContent":[".panels-page-container {\n    width: 100%;\n    min-height: 100vh; /* Ensure the height adjusts properly */\n    background-color: #f0f0f0;\n    display: flex;\n    flex-direction: column; /* Stack header, body, and footer vertically */\n}\n\n.panels-header {\n    width: 100%;\n    padding: 10px 20px;\n    background-color: #fff;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    z-index: 1000;\n}\n\n.panels-header img {\n    height: 50px;\n}\n\n.panels-body-container {\n    width: 100%;\n    padding: 20px;\n    display: flex;\n    justify-content: center;\n    flex-grow: 1; /* Make the body take up the remaining space */\n}\n\n.panels-content-container {\n    width: 100%;\n}\n\n.panels-table-container {\n    width: 100%;\n    height: 100%;\n}\n\n/* Responsive adjustments for mobile */\n@media (max-width: 768px) {\n    .panels-body-container {\n        padding: 15px;\n    }\n\n    .panels-header img {\n        height: 40px; /* Reduce header logo size */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
