// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.do-not-overpay-section {
    margin: 60px 0; /* Significant margin on top and bottom for spacing */
    padding-right: 20%; /* Move the entire content further to the right */
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align all content to the right */
    text-align: right; /* Right-align text */
}

.do-not-overpay-heading-container {
    max-width: 900px;
    margin-bottom: 20px;
}

.do-not-overpay-heading {
    font-size: 3rem;
    font-weight: bold;
    color: #706F70;
}

.highlight {
    color: #ff4747; /* Highlighted text color */
}

.do-not-overpay-description-container {
    max-width: 550px;
    margin-bottom: 30px;
}

.do-not-overpay-description {
    font-size: 1.5rem; /* Adjusted font size for consistency */
    color: #706F70;
    margin: 0;
}

.do-not-overpay-buttons {
    display: flex;
    justify-content: flex-end; /* Right-align button */
}

.primary-button {
    width: 200px; /* Fixed width for the primary button */
    padding: 10px 20px; /* Padding to ensure proper height */
}
`, "",{"version":3,"sources":["webpack://./src/components/speed_swab/do_not_overpay/SpeedSwabsDoNotOverpay.css"],"names":[],"mappings":"AAAA;IACI,cAAc,EAAE,qDAAqD;IACrE,kBAAkB,EAAE,iDAAiD;IACrE,aAAa;IACb,sBAAsB;IACtB,qBAAqB,EAAE,mCAAmC;IAC1D,iBAAiB,EAAE,qBAAqB;AAC5C;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,cAAc,EAAE,2BAA2B;AAC/C;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB,EAAE,uCAAuC;IAC1D,cAAc;IACd,SAAS;AACb;;AAEA;IACI,aAAa;IACb,yBAAyB,EAAE,uBAAuB;AACtD;;AAEA;IACI,YAAY,EAAE,uCAAuC;IACrD,kBAAkB,EAAE,oCAAoC;AAC5D","sourcesContent":[".do-not-overpay-section {\n    margin: 60px 0; /* Significant margin on top and bottom for spacing */\n    padding-right: 20%; /* Move the entire content further to the right */\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end; /* Align all content to the right */\n    text-align: right; /* Right-align text */\n}\n\n.do-not-overpay-heading-container {\n    max-width: 900px;\n    margin-bottom: 20px;\n}\n\n.do-not-overpay-heading {\n    font-size: 3rem;\n    font-weight: bold;\n    color: #706F70;\n}\n\n.highlight {\n    color: #ff4747; /* Highlighted text color */\n}\n\n.do-not-overpay-description-container {\n    max-width: 550px;\n    margin-bottom: 30px;\n}\n\n.do-not-overpay-description {\n    font-size: 1.5rem; /* Adjusted font size for consistency */\n    color: #706F70;\n    margin: 0;\n}\n\n.do-not-overpay-buttons {\n    display: flex;\n    justify-content: flex-end; /* Right-align button */\n}\n\n.primary-button {\n    width: 200px; /* Fixed width for the primary button */\n    padding: 10px 20px; /* Padding to ensure proper height */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
