// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-number-details-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.client-number-details-modal {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 500px;
    padding: 20px;
    position: relative;
    animation: fadeIn 0.3s ease-out;
}

.modal-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.modal-content h2 {
    font-size: 1.4em;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
}

.search-result-item {
    background: #f5f5f5;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.search-result-item h3 {
    font-size: 1.1em;
    color: #555;
    margin-bottom: 5px;
}

.search-result-item p {
    margin: 0;
    color: #666;
    font-size: 0.9em;
}

@keyframes fadeIn {
    from { opacity: 0; transform: scale(0.9); }
    to { opacity: 1; transform: scale(1); }
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/client_number/ClientNumberDetails.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,yCAAyC;IACzC,UAAU;IACV,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,+BAA+B;AACnC;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,SAAS;IACT,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,OAAO,UAAU,EAAE,qBAAqB,EAAE;IAC1C,KAAK,UAAU,EAAE,mBAAmB,EAAE;AAC1C","sourcesContent":[".client-number-details-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.6);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n}\n\n.client-number-details-modal {\n    background: white;\n    border-radius: 8px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);\n    width: 90%;\n    max-width: 500px;\n    padding: 20px;\n    position: relative;\n    animation: fadeIn 0.3s ease-out;\n}\n\n.modal-header {\n    display: flex;\n    justify-content: flex-end;\n    margin-bottom: 15px;\n}\n\n.modal-content h2 {\n    font-size: 1.4em;\n    color: #333;\n    margin-bottom: 10px;\n    text-align: center;\n}\n\n.search-result-item {\n    background: #f5f5f5;\n    padding: 15px;\n    margin-bottom: 10px;\n    border-radius: 5px;\n    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);\n}\n\n.search-result-item h3 {\n    font-size: 1.1em;\n    color: #555;\n    margin-bottom: 5px;\n}\n\n.search-result-item p {\n    margin: 0;\n    color: #666;\n    font-size: 0.9em;\n}\n\n@keyframes fadeIn {\n    from { opacity: 0; transform: scale(0.9); }\n    to { opacity: 1; transform: scale(1); }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
