import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


export const requestQuestOrder = createAsyncThunk(
    'questOrders/requestQuestOrder',
    async (thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        // return await fetchOrders(accessToken);
        return null
    }
);

const questOrdersSlice = createSlice({
    name: 'questOrders',
    initialState: {
        isQuestOrderDetailsVisible: false,
        questOrders: [],
        selectedOrganization: null,
        status: 'idle',
        error: null,
    },
    reducers: {
        // Clears the quest orders data
        clearQuestOrdersData: (state) => {
            state.questOrders = [];
        },
        // Toggles the visibility of the quest order modal
        toggleQuestOrderDetailModal: (state) => {
            state.isQuestOrderDetailsVisible = !state.isQuestOrderDetailsVisible;
        },
        // Sets the selected organization data for display in the modal
        setOrderSelectedOrganization: (state, action) => {
            state.selectedOrganization = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(requestQuestOrder.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(requestQuestOrder.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.questOrders = action.payload || [];
            })
            .addCase(requestQuestOrder.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

// Export the actions to be used in components
export const { toggleQuestOrderDetailModal, setOrderSelectedOrganization } = questOrdersSlice.actions;

export default questOrdersSlice.reducer;