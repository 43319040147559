// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Billing.css styles */
.billing-page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f0f2f5;
}

.billing-actions,
.billing-section {
    width: 100%; /* Adjusted for full width */
    margin: 0 auto; /* Centering */
    background-color: #ffffff; /* White background */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    padding: 20px; /* Adjusted padding */
}


/* Ensuring consistency with screens-body-container for any additional body content */
.screens-body-container,
.billing-body-container { /* Assuming you might want to apply similar styling for billing body */
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 20px;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .billing-actions,
    .billing-section,
    .screens-content-container {
        padding: 10px; /* Reduced padding on smaller screens */
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/quickbooks_billing/QuickbooksBilling.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;;IAEI,WAAW,EAAE,4BAA4B;IACzC,cAAc,EAAE,cAAc;IAC9B,yBAAyB,EAAE,qBAAqB;IAChD,kBAAkB,EAAE,oBAAoB;IACxC,wCAAwC,EAAE,qBAAqB;IAC/D,aAAa,EAAE,qBAAqB;AACxC;;;AAGA,qFAAqF;AACrF;0BAC0B,sEAAsE;IAC5F,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,aAAa;AACjB;;AAEA,+CAA+C;AAC/C;IACI;;;QAGI,aAAa,EAAE,uCAAuC;IAC1D;AACJ","sourcesContent":["/* Billing.css styles */\n.billing-page-container {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n    background-color: #f0f2f5;\n}\n\n.billing-actions,\n.billing-section {\n    width: 100%; /* Adjusted for full width */\n    margin: 0 auto; /* Centering */\n    background-color: #ffffff; /* White background */\n    border-radius: 8px; /* Rounded corners */\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */\n    padding: 20px; /* Adjusted padding */\n}\n\n\n/* Ensuring consistency with screens-body-container for any additional body content */\n.screens-body-container,\n.billing-body-container { /* Assuming you might want to apply similar styling for billing body */\n    flex-grow: 1;\n    display: flex;\n    justify-content: center;\n    padding: 20px;\n}\n\n/* Responsive adjustments for smaller screens */\n@media (max-width: 768px) {\n    .billing-actions,\n    .billing-section,\n    .screens-content-container {\n        padding: 10px; /* Reduced padding on smaller screens */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
