// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.public-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F0F0F0;
    padding: 10px 20px;
}

.logo-link {
    display: flex;
    align-items: center;
}

.header-logo {
    height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/components/headers/public_header/PublicHeader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".public-header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    background-color: #F0F0F0;\n    padding: 10px 20px;\n}\n\n.logo-link {\n    display: flex;\n    align-items: center;\n}\n\n.header-logo {\n    height: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
