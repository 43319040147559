import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import './QuestEspManagementModal.css';
import {
    clearQuestEspManagementData,
    requestEspCollectorAccess,
    toggleQuestEspManagementModal
} from "../../../store/quest_esp/questEspSlice";
import {PrimaryButton} from "../../buttons/primary_button/PrimaryButton";
import {IconButton} from "../../buttons/icon_button/IconButton";
import {GenericIcons} from "../../../assets/icons";


const QuestEspManagementModal = () => {
    const dispatch = useDispatch();
    const isQuestEspManagementVisible = useSelector(state => state.questEspManagement.isQuestEspManagementVisible);
    const selectedOrganization = useSelector(state => state.questEspManagement.selectedOrganization);

    const [selectedCollector, setSelectedCollector] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [requestDotAccount, setRequestDotAccount] = useState(false);
    const [requestNonDotAccount, setRequestNonDotAccount] = useState(false);

    const collectorsOptions = [
        { value: 'collector_1', label: 'Collector 1' },
        { value: 'collector_2', label: 'Collector 2' },
        // Add more collectors if needed
    ];

    const accountNumberOptions = [
        { value: 'account_001', label: 'Account 001' },
        { value: 'account_002', label: 'Account 002' },
        // Add more account numbers as needed
    ];

    const hasDotAccount = !!selectedOrganization?.organization_dot_sub_account_number;
    const hasNonDotAccount = !!selectedOrganization?.organization_sub_account_number;

    const handleToggleDotAccount = () => {
        if (!hasDotAccount) setRequestDotAccount(!requestDotAccount);
    };

    const handleToggleNonDotAccount = () => {
        if (!hasNonDotAccount) setRequestNonDotAccount(!requestNonDotAccount);
    };

    const handleSubmitRequest = () => {
        dispatch(requestEspCollectorAccess({
            collector: selectedCollector,
            accounts: selectedAccounts,
            requestDotAccount,
            requestNonDotAccount,
        }));
    };

    const handleCloseModal = () => {
        dispatch(toggleQuestEspManagementModal());
        dispatch(clearQuestEspManagementData())
        dispatch(toggleQuestEspManagementModal())
    };

    if (!isQuestEspManagementVisible) return null;

    return (
        <div className="esp-management-modal-overlay">
            <div className="esp-management-modal">
                <div className="esp-management-modal-header">
                    <IconButton hoverIcon={GenericIcons.ExitIcon} defaultIcon={GenericIcons.CloseIcon} onClick={handleCloseModal} />
                </div>
                <h2 className="esp-management-modal-title">Quest ESP Management</h2>

                <div className="modal-section">
                    <label>Select Collectors:</label>
                    <Select
                        options={collectorsOptions}
                        value={selectedCollector}
                        onChange={setSelectedCollector}
                        placeholder="Choose collectors"
                        isMulti
                        isClearable={false}
                        closeMenuOnSelect={false}
                    />
                </div>

                <div className="modal-section">
                    <label>Select Account Numbers:</label>
                    <Select
                        options={accountNumberOptions}
                        value={selectedAccounts}
                        onChange={setSelectedAccounts}
                        placeholder="Choose account numbers"
                        isMulti
                        isClearable={false}
                        closeMenuOnSelect={false}
                    />
                </div>

                <div className="modal-section account-type-toggle">
                    <label>Request Account Type:</label>
                    <div className="account-toggle-options">
                        <label>
                            <input
                                type="checkbox"
                                checked={requestDotAccount}
                                onChange={handleToggleDotAccount}
                                disabled={hasDotAccount}
                            />
                            DOT Account
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={requestNonDotAccount}
                                onChange={handleToggleNonDotAccount}
                                disabled={hasNonDotAccount}
                            />
                            Non-DOT Account
                        </label>
                    </div>
                </div>

                <PrimaryButton className="submit-request-button" onClick={handleSubmitRequest}>
                    Submit Request
                </PrimaryButton>
            </div>
        </div>
    );
};

export default QuestEspManagementModal;
