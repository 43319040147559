// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* OrganizationsTable.css */
.organization-container {
    width: 100%;
    padding: 20px;
    overflow-x: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.organization-scroll-container {
    display: block;
    background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/clients/organizations/organizations_table/OrganizationsTable.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,cAAc;IACd,sBAAsB;AAC1B","sourcesContent":["/* OrganizationsTable.css */\n.organization-container {\n    width: 100%;\n    padding: 20px;\n    overflow-x: auto;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.organization-scroll-container {\n    display: block;\n    background-color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
