// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.speed-product-faq {
    width: 100%;
    padding: 20px;
    margin-top: 20px;
}

.speed-product-faq h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-align: center;
}

.speed-product-faq p {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 20px;
}

.faq-item {
    margin-bottom: 15px;
}

.faq-item button {
    display: block;
    width: 100%;
    background-color: #f0f0f0;
    border: none;
    text-align: left;
    padding: 15px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.faq-item button:hover {
    background-color: #e0e0e0;
}

.faq-item p {
    padding: 10px;
    background-color: #e8f0fe;
    border-left: 4px solid blue;
    margin: 0;
    display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/shopify/product/faq/ProductFaq.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,eAAe;IACf,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,2BAA2B;IAC3B,SAAS;IACT,cAAc;AAClB","sourcesContent":[".speed-product-faq {\n    width: 100%;\n    padding: 20px;\n    margin-top: 20px;\n}\n\n.speed-product-faq h2 {\n    font-size: 1.5rem;\n    margin-bottom: 10px;\n    text-align: center;\n}\n\n.speed-product-faq p {\n    text-align: center;\n    font-size: 1rem;\n    margin-bottom: 20px;\n}\n\n.faq-item {\n    margin-bottom: 15px;\n}\n\n.faq-item button {\n    display: block;\n    width: 100%;\n    background-color: #f0f0f0;\n    border: none;\n    text-align: left;\n    padding: 15px;\n    font-size: 1rem;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n}\n\n.faq-item button:hover {\n    background-color: #e0e0e0;\n}\n\n.faq-item p {\n    padding: 10px;\n    background-color: #e8f0fe;\n    border-left: 4px solid blue;\n    margin: 0;\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
