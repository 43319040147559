// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.speed-swabs-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 10px 20px;
    position: fixed; /* Make the header sticky */
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Smooth transition */
    opacity: 1; /* Fully visible */
}

.speed-swabs-header.hidden {
    transform: translateY(-100%); /* Move header out of view */
    opacity: 0; /* Hide header */
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    height: 50px;
}

.header-right {
    display: flex;
    align-items: center;
}

.navbar-container {
    display: flex;
    margin-right: 20px;
}

.nav-item {
    margin-left: 10px;
}

.nav-icon {
    width: 30px;
    height: 30px;
}

.user-icon-card {
    margin-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/headers/speed_swabs_header/SpeedSwabsHeader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,yBAAyB;IACzB,kBAAkB;IAClB,eAAe,EAAE,2BAA2B;IAC5C,MAAM;IACN,OAAO;IACP,QAAQ;IACR,aAAa;IACb,gEAAgE,EAAE,sBAAsB;IACxF,UAAU,EAAE,kBAAkB;AAClC;;AAEA;IACI,4BAA4B,EAAE,4BAA4B;IAC1D,UAAU,EAAE,gBAAgB;AAChC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".speed-swabs-header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    background-color: #f0f0f0;\n    padding: 10px 20px;\n    position: fixed; /* Make the header sticky */\n    top: 0;\n    left: 0;\n    right: 0;\n    z-index: 1000;\n    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Smooth transition */\n    opacity: 1; /* Fully visible */\n}\n\n.speed-swabs-header.hidden {\n    transform: translateY(-100%); /* Move header out of view */\n    opacity: 0; /* Hide header */\n}\n\n.logo-container {\n    display: flex;\n    align-items: center;\n}\n\n.logo {\n    height: 50px;\n}\n\n.header-right {\n    display: flex;\n    align-items: center;\n}\n\n.navbar-container {\n    display: flex;\n    margin-right: 20px;\n}\n\n.nav-item {\n    margin-left: 10px;\n}\n\n.nav-icon {\n    width: 30px;\n    height: 30px;\n}\n\n.user-icon-card {\n    margin-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
