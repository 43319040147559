// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* SpeedSwabsOverview.css */
.speed-swabs-overview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #FFFFFF;
}

.speed-swabs-hero-graphic-container {
    position: fixed;
    right: 0;
    top: 30%;
    transform: translateY(-50%);
    z-index: 1;
    max-width: 600px;
}

.feature-section-hero-text-wrapper {
    width: 100%;
    padding: 60px 0;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;
}

.how-to-use-section-wrapper {
    width: 100%;
    margin-top: 40px;
    display: flex;
}


.do-not-overpay-section-wrapper {
    width: 100%;
    margin-top: 200px;
    margin-bottom: 200px;
}

@media (max-width: 768px) {
    .speed-swabs-hero-graphic-container {
        position: static;
        transform: none;
        max-width: 100%;
        margin: 0 auto;
    }

    .feature-section-hero-text {
        margin-left: 0;
        text-align: center;
    }

    .how-to-use-section-wrapper {
        padding: 10px;
    }

    .do-not-overpay-section-wrapper {
        padding: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/speed_swab/overview/SpeedSwabsOverview.css"],"names":[],"mappings":";AACA,2BAA2B;AAC3B;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,QAAQ;IACR,QAAQ;IACR,2BAA2B;IAC3B,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,aAAa;AACjB;;;AAGA;IACI,WAAW;IACX,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI;QACI,gBAAgB;QAChB,eAAe;QACf,eAAe;QACf,cAAc;IAClB;;IAEA;QACI,cAAc;QACd,kBAAkB;IACtB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,aAAa;IACjB;AACJ","sourcesContent":["\n/* SpeedSwabsOverview.css */\n.speed-swabs-overview-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    position: relative;\n    overflow: hidden;\n    background-color: #FFFFFF;\n}\n\n.speed-swabs-hero-graphic-container {\n    position: fixed;\n    right: 0;\n    top: 30%;\n    transform: translateY(-50%);\n    z-index: 1;\n    max-width: 600px;\n}\n\n.feature-section-hero-text-wrapper {\n    width: 100%;\n    padding: 60px 0;\n    display: flex;\n    justify-content: center;\n    position: relative;\n    z-index: 2;\n}\n\n.how-to-use-section-wrapper {\n    width: 100%;\n    margin-top: 40px;\n    display: flex;\n}\n\n\n.do-not-overpay-section-wrapper {\n    width: 100%;\n    margin-top: 200px;\n    margin-bottom: 200px;\n}\n\n@media (max-width: 768px) {\n    .speed-swabs-hero-graphic-container {\n        position: static;\n        transform: none;\n        max-width: 100%;\n        margin: 0 auto;\n    }\n\n    .feature-section-hero-text {\n        margin-left: 0;\n        text-align: center;\n    }\n\n    .how-to-use-section-wrapper {\n        padding: 10px;\n    }\n\n    .do-not-overpay-section-wrapper {\n        padding: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
