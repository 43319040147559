// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fusion-footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    width: 100%;
}

.fusion-footer p {
    margin: 0;
    font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/footers/fusion_footer/FusionFooter.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,0CAA0C;IAC1C,WAAW;AACf;;AAEA;IACI,SAAS;IACT,eAAe;AACnB","sourcesContent":[".fusion-footer {\n    background-color: #333;\n    color: #fff;\n    text-align: center;\n    padding: 20px 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 14px;\n    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);\n    width: 100%;\n}\n\n.fusion-footer p {\n    margin: 0;\n    font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
