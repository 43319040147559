import React from "react";
import "./SpeedSwabsCompare.css";
import Masonry from 'react-masonry-css';
import { useSelector } from 'react-redux';
import {SpeedIcons} from "../../../assets/icons";

const breakpointColumnsObj = {
    default: 3,
    1200: 2,
    800: 1
};

const SpeedSwabsCompare = () => {
    // Fetch products from Redux store with the correct path
    const products = useSelector((state) => state.shopifyProducts.allPublicShopifyProducts);

    return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="speed-swabs-compare-masonry-grid"
            columnClassName="speed-swabs-compare-masonry-grid_column"
        >
            {products?.data?.products?.edges?.map((productEdge) => {
                const product = productEdge.node;
                return (
                    <div className={`speed-swabs-compare-product-card ${!product.featuredImage?.url ? 'no-image' : ''}`} key={product.id}>
                        {/* Only show the image if it exists */}
                        {product.featuredImage?.url && (
                            <div className="speed-swabs-compare-product-images">
                                <img src={product.featuredImage.url} alt={product.title} className="speed-swabs-compare-product-image" />
                            </div>
                        )}
                        <h3 className="speed-swabs-compare-product-name">{product.title}</h3>
                        <p className="speed-swabs-compare-product-description">Oral Drug Screen</p>
                        {/* Placeholder for product options */}
                        <div className="speed-swabs-compare-product-options">
                            <div className="speed-swabs-compare-option">
                                <div className="speed-swabs-compare-option-label">OPI</div>
                                <div className="speed-swabs-compare-option-value">50 ng/mL</div>
                            </div>
                            <div className="speed-swabs-compare-option">
                                <div className="speed-swabs-compare-option-label">COC</div>
                                <div className="speed-swabs-compare-option-value">300 ng/mL</div>
                            </div>
                        </div>
                        <button className="speed-swabs-compare-add-to-cart-button">
                            <img alt="Speed Cart Icon" src={SpeedIcons.SpeedCartIcon} /> Add to cart
                        </button>
                    </div>
                );
            })}
        </Masonry>
    );
};

export default SpeedSwabsCompare;
