// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* CSS (SpeedSwabsHeroGraphic.css) */
.speed-swabs-hero-graphic {
    position: fixed;
    top: 150px; /* Adjust as needed for spacing */
    right: 0;
    height: 100%;
    z-index: 0;
}

.graphic-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 100%;
}

.speed-swabs-image {
    width: auto;
    max-width: 700px; /* Adjust the max width for better fitting */
    max-height: 900px; /* Adjust max height */
    object-fit: contain;
    transition: transform 0.1s ease-out;
}

@media (max-width: 992px) {
    .speed-swabs-image {
        margin-right: -15%;
        max-width: 500px; /* Adjust the max width for better fitting */
        max-height: 700px; /* Adjust max height */
    }
}

@media (max-width: 768px) {
    .speed-swabs-hero-graphic {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/speed_swab/hero_graphic/SpeedSwabsHeroGraphic.css"],"names":[],"mappings":";AACA,oCAAoC;AACpC;IACI,eAAe;IACf,UAAU,EAAE,iCAAiC;IAC7C,QAAQ;IACR,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,WAAW;IACX,gBAAgB,EAAE,4CAA4C;IAC9D,iBAAiB,EAAE,sBAAsB;IACzC,mBAAmB;IACnB,mCAAmC;AACvC;;AAEA;IACI;QACI,kBAAkB;QAClB,gBAAgB,EAAE,4CAA4C;QAC9D,iBAAiB,EAAE,sBAAsB;IAC7C;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["\n/* CSS (SpeedSwabsHeroGraphic.css) */\n.speed-swabs-hero-graphic {\n    position: fixed;\n    top: 150px; /* Adjust as needed for spacing */\n    right: 0;\n    height: 100%;\n    z-index: 0;\n}\n\n.graphic-container {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    height: 100%;\n    width: 100%;\n}\n\n.speed-swabs-image {\n    width: auto;\n    max-width: 700px; /* Adjust the max width for better fitting */\n    max-height: 900px; /* Adjust max height */\n    object-fit: contain;\n    transition: transform 0.1s ease-out;\n}\n\n@media (max-width: 992px) {\n    .speed-swabs-image {\n        margin-right: -15%;\n        max-width: 500px; /* Adjust the max width for better fitting */\n        max-height: 700px; /* Adjust max height */\n    }\n}\n\n@media (max-width: 768px) {\n    .speed-swabs-hero-graphic {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
