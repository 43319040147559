// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container for industry buttons */
.industry-selector-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; /* Adds space between buttons */
    justify-content: space-between; /* Evenly space buttons */
}

/* Basic button styling */
.industry-selector-button {
    padding: 0.75rem 1.5rem;
    border: 1px solid #dcdcdc;
    border-radius: 50px; /* Rounded corners for pill-like style */
    background-color: #f1f1f1;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(50% - 1rem); /* Ensures two buttons fit per row */
    text-align: center;
}

/* Selected state styling */
.industry-selector-button-selected {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

/* Hover and focus states */
.industry-selector-button:hover,
.industry-selector-button:focus {
    background-color: #e0e0e0;
    border-color: #007bff;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/forms/assets/industry_selector/IndustrySelector.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,aAAa;IACb,eAAe;IACf,SAAS,EAAE,+BAA+B;IAC1C,8BAA8B,EAAE,yBAAyB;AAC7D;;AAEA,yBAAyB;AACzB;IACI,uBAAuB;IACvB,yBAAyB;IACzB,mBAAmB,EAAE,wCAAwC;IAC7D,yBAAyB;IACzB,eAAe;IACf,uDAAuD;IACvD,YAA0B;IAA1B,cAA0B;IAA1B,4BAA0B,EAAE,oCAAoC;IAChE,kBAAkB;AACtB;;AAEA,2BAA2B;AAC3B;IACI,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;AACzB;;AAEA,2BAA2B;AAC3B;;IAEI,yBAAyB;IACzB,qBAAqB;AACzB","sourcesContent":["/* Container for industry buttons */\n.industry-selector-container {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 1rem; /* Adds space between buttons */\n    justify-content: space-between; /* Evenly space buttons */\n}\n\n/* Basic button styling */\n.industry-selector-button {\n    padding: 0.75rem 1.5rem;\n    border: 1px solid #dcdcdc;\n    border-radius: 50px; /* Rounded corners for pill-like style */\n    background-color: #f1f1f1;\n    cursor: pointer;\n    transition: background-color 0.3s ease, color 0.3s ease;\n    flex: 1 1 calc(50% - 1rem); /* Ensures two buttons fit per row */\n    text-align: center;\n}\n\n/* Selected state styling */\n.industry-selector-button-selected {\n    background-color: #007bff;\n    color: white;\n    border-color: #007bff;\n}\n\n/* Hover and focus states */\n.industry-selector-button:hover,\n.industry-selector-button:focus {\n    background-color: #e0e0e0;\n    border-color: #007bff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
