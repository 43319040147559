// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-row {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    align-items: center;
}

.client-info {
    display: flex;
    flex-direction: column;
    flex: 2 1;
    gap: 5px;
}

.client-company-cell {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    text-align: left;
}

.client-address-cell {
    font-size: 14px;
    color: #666;
    text-align: left;
}

.client-action {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1 1;
    justify-content: flex-end;
}

.client-action-tray-cell {
    display: flex;
    justify-content: center;
    position: relative;
}


.client-expand-cell {
    display: flex;
    justify-content: center;
    align-items: center;
}

.client-expanded-row {
    background-color: #f9f9f9;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
}

.client-expanded-content {
    padding: 15px 0;
    text-indent: 5%;
}

.client-expanded-row .client-expanded-content .action-tray {
    padding-left: 10%;
}
`, "",{"version":3,"sources":["webpack://./src/components/clients/clients_row/ClientsRow.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;IAClB,6BAA6B;IAC7B,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAO;IACP,QAAQ;AACZ;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,SAAO;IACP,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;AACtB;;;AAGA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,6BAA6B;AACjC;;AAEA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".client-row {\n    display: flex;\n    justify-content: space-between;\n    padding: 10px 20px;\n    border-bottom: 1px solid #ddd;\n    background-color: #fff;\n    align-items: center;\n}\n\n.client-info {\n    display: flex;\n    flex-direction: column;\n    flex: 2;\n    gap: 5px;\n}\n\n.client-company-cell {\n    font-size: 16px;\n    font-weight: bold;\n    color: #333;\n    text-align: left;\n}\n\n.client-address-cell {\n    font-size: 14px;\n    color: #666;\n    text-align: left;\n}\n\n.client-action {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    flex: 1;\n    justify-content: flex-end;\n}\n\n.client-action-tray-cell {\n    display: flex;\n    justify-content: center;\n    position: relative;\n}\n\n\n.client-expand-cell {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.client-expanded-row {\n    background-color: #f9f9f9;\n    padding: 10px 20px;\n    border-bottom: 1px solid #ddd;\n}\n\n.client-expanded-content {\n    padding: 15px 0;\n    text-indent: 5%;\n}\n\n.client-expanded-row .client-expanded-content .action-tray {\n    padding-left: 10%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
