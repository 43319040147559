// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.speed-product-details .product-info .product-features {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 16px;
    font-weight: 500;
    color: #706F70;
    margin: 20px 0;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.speed-product-details .product-info .product-features div {
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.speed-product-details .product-info .product-features div:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/pages/shopify/speed/product/features/SpeedProductDetailFeatures.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yCAAyC;IACzC,qDAAqD;AACzD;;AAEA;IACI,2BAA2B;IAC3B,yCAAyC;AAC7C","sourcesContent":[".speed-product-details .product-info .product-features {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n    font-size: 16px;\n    font-weight: 500;\n    color: #706F70;\n    margin: 20px 0;\n    padding: 10px;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n}\n\n.speed-product-details .product-info .product-features div {\n    padding: 10px;\n    background-color: #fff;\n    border-radius: 4px;\n    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);\n    transition: transform 0.2s ease, box-shadow 0.2s ease;\n}\n\n.speed-product-details .product-info .product-features div:hover {\n    transform: translateY(-4px);\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
