import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Async thunk for initiating laboratory billing export
export const getAccountCreationRequest = createAsyncThunk(
    'laboratory/getAccountCreationRequest',
    async (params) => {
        const dateRange = params.filters.date_range;
        const accessToken = sessionStorage.getItem("accessToken");
        // return await fetchLaboratoryBillingExport(dateRange, accessToken);
        return null;
    }
);

// Initial state of the laboratory slice
const initialState = {
    isAccountCreationRequestModalVisible: false,
    accountRequestData: [],
    selectedOrganization: [],
    status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    error: null,
};

// Laboratory slice
const questAccountRequestSlice= createSlice({
    name: 'questAccountRequests',
    initialState,
    reducers: {
        clearQuestAccountRequestData: (state) => {
            state.accountRequestData = [];
            state.selectedOrganization = [];
            state.status = 'idle';
            state.error = null;
        },
        // Toggles the visibility of the quest order modal
        toggleQuestAccountManagementModal: (state) => {
            state.isAccountCreationRequestModalVisible = !state.isAccountCreationRequestModalVisible;
        },
        // Sets the selected organization data for display in the modal
        setAccountSelectedOrganization: (state, action) => {
            state.selectedOrganization = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAccountCreationRequest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAccountCreationRequest.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.accountRequestData = action.payload || [];
            })
            .addCase(getAccountCreationRequest.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { clearQuestAccountRequestData, toggleQuestAccountManagementModal, setAccountSelectedOrganization } = questAccountRequestSlice.actions;

export default questAccountRequestSlice.reducer;
