// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/* ProductPriceDetails.css */
.product-price {
    font-size: 2.5rem;
    color: #706F70;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: left;
}

.product-per-kit-price {
    font-size: 1.2rem;
    color: #706F70;
    margin-top: 0;
    font-weight: normal;
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/shopify/product/details/price/ProductPriceDetails.css"],"names":[],"mappings":";;AAEA,4BAA4B;AAC5B;IACI,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":["\n\n/* ProductPriceDetails.css */\n.product-price {\n    font-size: 2.5rem;\n    color: #706F70;\n    margin-bottom: 10px;\n    font-weight: bold;\n    text-align: left;\n}\n\n.product-per-kit-price {\n    font-size: 1.2rem;\n    color: #706F70;\n    margin-top: 0;\n    font-weight: normal;\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
