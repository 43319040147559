import React, { useEffect } from 'react';
import './OrganizationsPage.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPanelsByLabbThunk } from '../../store/panels/panelsSlice';
import ShopifyHeader from '../../components/headers/shopify_header/ShopifyHeader';
import LabFusionLogo from '../../assets/logo/LabFusionLogo.png';
import LabbLogo from '../../assets/logo/LabbLogo.png';
import { useShopifyLogout } from "../../utils/authUtils";
import OrganizationsTable from "../../components/clients/organizations/organizations_table/OrganizationsTable";

const OrganizationsPage = () => {
    const dispatch = useDispatch();
    const onLogout = useShopifyLogout();

    // Get Shopify user details
    const lssCustomerDetails = useSelector((state) => state.shopifyUser.lssCollectorDetails);
    // Function to format user roles from lssCollectorDetails
    const getUserRoles = (lssCollectorDetails) => {
        const roles = [];
        if (lssCollectorDetails.can_manage_results) roles.push("can_manage_results");
        if (lssCollectorDetails.can_order_cartons) roles.push("can_order_cartons");
        if (lssCollectorDetails.can_manage_screeners) roles.push("can_manage_screeners");
        if (lssCollectorDetails.can_perform_screens) roles.push("can_perform_screens");
        if (lssCollectorDetails.can_manage_panels) roles.push("can_manage_panels");
        return roles;
    };

    const clientId = lssCustomerDetails?.client_id;  // Assuming `id` is the client identifier

    const hostname = window.location.hostname;
    const logoSrc = hostname === "fusion.labb.com" ? LabFusionLogo : LabbLogo;
    const docTitle = hostname === 'fusion.labb.com' ? "Lab Fusion | Manage Organizations" : "Labb | Manage Organizations";

    useEffect(() => {
        document.title = docTitle;
    }, [dispatch, docTitle]);

    return (
        <div className="panels-page-container">
            <div className="panels-header-container">
                <ShopifyHeader logoSrc={logoSrc} onLogout={onLogout} />
            </div>
            <div className="panels-body-container">
                <div className="panels-content-container">
                    {clientId ? (
                        <OrganizationsTable clientId={clientId} userRoles={getUserRoles(lssCustomerDetails)} />
                    ) : (
                        <p>No organization data available for the logged-in user.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OrganizationsPage;
