// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* VariantSelector.css */
.panel-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 30px;
    flex-wrap: nowrap;
}

.panel-select {
    min-width: 600px;
    width: 40%;
    padding: 12px;
    font-size: 1rem;
    border-radius: 25px;
    border: 2px solid #706F70;
    background-color: #fff;
    appearance: none;
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-size: 10px;
    margin-bottom: 0;
}

.panel-select-text {
    color: #706F70;
}

.add-to-cart-button {
    padding: 5px 30px;
    font-size: 1.2rem;
    background-color: #28A745;
    color: white;
    border-radius: 25px;
    border: none;
    white-space: nowrap;
    cursor: pointer;
    gap: 8px;
    transition: background-color 0.3s ease;
    height: auto;
    flex-shrink: 0;
    display: flex;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/shopify/product/details/variant_selector/VariantSelector.css"],"names":[],"mappings":";AACA,wBAAwB;AACxB;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,SAAS;IACT,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,yBAAyB;IACzB,sBAAsB;IACtB,gBAAgB;IAChB,sCAAsC;IACtC,4BAA4B;IAC5B,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,QAAQ;IACR,sCAAsC;IACtC,YAAY;IACZ,cAAc;IACd,aAAa;IACb,mBAAmB;AACvB","sourcesContent":["\n/* VariantSelector.css */\n.panel-container {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 20px;\n    margin-bottom: 30px;\n    flex-wrap: nowrap;\n}\n\n.panel-select {\n    min-width: 600px;\n    width: 40%;\n    padding: 12px;\n    font-size: 1rem;\n    border-radius: 25px;\n    border: 2px solid #706F70;\n    background-color: #fff;\n    appearance: none;\n    background-position: right 15px center;\n    background-repeat: no-repeat;\n    background-size: 10px;\n    margin-bottom: 0;\n}\n\n.panel-select-text {\n    color: #706F70;\n}\n\n.add-to-cart-button {\n    padding: 5px 30px;\n    font-size: 1.2rem;\n    background-color: #28A745;\n    color: white;\n    border-radius: 25px;\n    border: none;\n    white-space: nowrap;\n    cursor: pointer;\n    gap: 8px;\n    transition: background-color 0.3s ease;\n    height: auto;\n    flex-shrink: 0;\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
