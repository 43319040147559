// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.freddy-public-location-management-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.location-add-section,
.location-edit-section {
    background-color: #f7f7f7;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.location-add-section h2,
.location-edit-section h2 {
    margin-bottom: 15px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.location-list-section {
    margin-top: 20px;
}

.location-list-section h2 {
    margin-bottom: 10px;
}

.location-list-section ul {
    list-style-type: none;
    padding-left: 0;
}

.location-list-section li {
    padding: 10px;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
}

.location-list-section button {
    background-color: #007bff;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/locations/manage/FreddyPublicLocationManagementPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,cAAc;AAClB;;AAEA;;IAEI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;;IAEI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".freddy-public-location-management-page {\n    padding: 20px;\n    max-width: 1200px;\n    margin: 0 auto;\n}\n\n.location-add-section,\n.location-edit-section {\n    background-color: #f7f7f7;\n    padding: 20px;\n    margin-bottom: 20px;\n    border-radius: 8px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n}\n\n.location-add-section h2,\n.location-edit-section h2 {\n    margin-bottom: 15px;\n}\n\n.form-group {\n    margin-bottom: 15px;\n}\n\n.form-group label {\n    display: block;\n    margin-bottom: 5px;\n}\n\n.form-group input {\n    width: 100%;\n    padding: 8px;\n    border-radius: 4px;\n    border: 1px solid #ccc;\n}\n\n.location-list-section {\n    margin-top: 20px;\n}\n\n.location-list-section h2 {\n    margin-bottom: 10px;\n}\n\n.location-list-section ul {\n    list-style-type: none;\n    padding-left: 0;\n}\n\n.location-list-section li {\n    padding: 10px;\n    background-color: #fff;\n    margin-bottom: 10px;\n    border-radius: 4px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    display: flex;\n    justify-content: space-between;\n}\n\n.location-list-section button {\n    background-color: #007bff;\n    color: white;\n    padding: 5px 10px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
