// src/store/autobot/clients/organizations/organizationsSlice.js
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchOrganizationsAPI, fetchOrganizationsMatch, fetchAllOrganizationsAPI} from "../../../../services/api/v1/organizationsService";

// Asynchronous thunk for fetching organizations by clientId
export const fetchOrganizationsByClientIdMatch = createAsyncThunk(
    'organizations/fetchMatchedOrganizations',
    async ({filteredQuestSubAccounts, selectedClientAccountId }) => {
        const accessToken = sessionStorage.getItem('accessToken');
        return await fetchOrganizationsMatch(filteredQuestSubAccounts, selectedClientAccountId, accessToken);
    }
);
// Asynchronous thunk for fetching organizations by clientId
export const fetchOrganizationsByClientId = createAsyncThunk(
    'organizations/fetchOrganizationsByClientId',
    async ({selectedClientAccountId} ) => {
        const accessToken = sessionStorage.getItem('accessToken');
        return await fetchOrganizationsAPI(selectedClientAccountId, accessToken);
    }
);

// Asynchronous thunk for fetching organizations by clientId
export const fetchShopifyOrganizationsByClientId = createAsyncThunk(
    'organizations/fetchShopifyOrganizationsByClientId',
    async ({selectedClientAccountId} ) => {
        const accessToken = sessionStorage.getItem('shopifyAccessToken');
        return await fetchOrganizationsAPI(selectedClientAccountId, accessToken);
    }
);

// Asynchronous thunk for fetching organizations by clientId
export const fetchAllOrganizations = createAsyncThunk(
    'organizations/fetchAllOrganizations',
    async () => {
        const accessToken = sessionStorage.getItem('accessToken');
        return await fetchAllOrganizationsAPI(accessToken);
    }
);


const initialState = {
    matchedOrganizationData: [],
    allOrganizationData: [],
    organizationData: [],
    status: 'idle',
    error: null,
};


const organizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {
        clearOrganization: (state) => {
            state.matchedOrganizationData = [];
            state.allOrganizationData = [];
            state.organizationData = [];
            state.status = 'idle';
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrganizationsByClientId.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchOrganizationsByClientId.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.allOrganizationData = action.payload;
            })
            .addCase(fetchOrganizationsByClientId.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchOrganizationsByClientIdMatch.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchOrganizationsByClientIdMatch.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.matchedOrganizationData = action.payload;
            })
            .addCase(fetchOrganizationsByClientIdMatch.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchAllOrganizations.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAllOrganizations.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.organizationData = action.payload;
            })
            .addCase(fetchAllOrganizations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchShopifyOrganizationsByClientId.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchShopifyOrganizationsByClientId.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.organizationData = action.payload;
            })
            .addCase(fetchShopifyOrganizationsByClientId.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export const { setOrganization, clearOrganization } = organizationsSlice.actions;

export default organizationsSlice.reducer;
