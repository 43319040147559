// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* QuestAccountRequestModal.css */

.account-request-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.account-request-modal {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 500px;
    max-width: 90%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
}

.account-request-modal-header {
    display: flex;
    justify-content: flex-end;
}

.account-request-modal-title {
    text-align: center;
    margin-top: 0;
    font-size: 1.5em;
    color: #333;
}

.account-details-section {
    margin-top: 15px;
}

.account-details-section h3 {
    margin-bottom: 10px;
    color: #555;
}

.account-details-section div {
    margin-bottom: 8px;
    font-size: 0.95em;
    color: #333;
}

.action-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.action-buttons button {
    width: 100%;
    padding: 10px 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/quest_account_request/QuestAccountRequestModal.css"],"names":[],"mappings":"AAAA,iCAAiC;;AAEjC;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,cAAc;IACd,uCAAuC;IACvC,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB","sourcesContent":["/* QuestAccountRequestModal.css */\n\n.account-request-modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 10000;\n}\n\n.account-request-modal {\n    background-color: #fff;\n    border-radius: 8px;\n    padding: 20px;\n    width: 500px;\n    max-width: 90%;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\n    position: relative;\n}\n\n.account-request-modal-header {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.account-request-modal-title {\n    text-align: center;\n    margin-top: 0;\n    font-size: 1.5em;\n    color: #333;\n}\n\n.account-details-section {\n    margin-top: 15px;\n}\n\n.account-details-section h3 {\n    margin-bottom: 10px;\n    color: #555;\n}\n\n.account-details-section div {\n    margin-bottom: 8px;\n    font-size: 0.95em;\n    color: #333;\n}\n\n.action-buttons {\n    margin-top: 20px;\n    display: flex;\n    justify-content: center;\n}\n\n.action-buttons button {\n    width: 100%;\n    padding: 10px 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
