import React from 'react';
import './ShopifySpeedCartLargerPanels.css';
import SpeedSwabPlaceholder from '../../../../../assets/graphics/SpeedSwabRapidOralFluidTestSingleSwab.png'; // Placeholder image
import { PrimaryButton } from "../../../../buttons/primary_button/PrimaryButton";
import { SecondaryButton } from "../../../../buttons/secondary_button/SecondaryButton";

const ShopifySpeedCartLargerPanels = ({ cart }) => {
    const drugDetails = [
        { name: "THC", added: false },
        { name: "COC", added: true },
        { name: "AMP", added: true },
        { name: "OPI", added: false },
        { name: "MTD", added: true }
    ];

    return (
        <div className="cart-panel-section">
            {/* Product Image Section */}
            <div className="cart-panel-image">
                <img src={SpeedSwabPlaceholder} alt="SpeedSwabs Kit" />
            </div>

            {/* Upgrade Info and Actions */}
            <div className="cart-panel-content">
                <h3 className="cart-panel-title">Upgrade to SpeedSwab 8A</h3>

                {/* Drug Details */}
                <div className="drug-details">
                    {drugDetails.map((drug, index) => (
                        <div key={index} className={`drug-item ${drug.added ? 'added' : ''}`}>
                            {drug.name}
                        </div>
                    ))}
                </div>

                {/* Action Buttons */}
                <div className="cart-panel-buttons">
                    {/*<SecondaryButton className="learn-more-btn">Learn more</SecondaryButton>*/}
                    <PrimaryButton className="replace-cart-btn">Upgrade cart items</PrimaryButton>
                </div>
            </div>
        </div>
    );
};

export default ShopifySpeedCartLargerPanels;
