import React from 'react';
import ShopifySpeedCartModal from "../shopify/speed_cart/ShopifySpeedCartModal";
import CookieConsentBannerModal from "../cookie_consent/SpeedSwabsCookieConsentBanner";
import TalkSwabsModal from "../forms/progressive/talk_swabs/TalkSwabs";
import QuestOrderModal from "../quest_orders/QuestOrdersModal";
import QuestEspManagementModal from "../quest_esp_management/QuestEspManagementModal";
import QuestAccountRequestModal from "../quest_account_request/QuestAccountRequestModal";


const UnauthModals = () => {
    return (
        <>
            <CookieConsentBannerModal />
            <ShopifySpeedCartModal />
            <TalkSwabsModal />
            <QuestOrderModal />
            <QuestEspManagementModal />
            <QuestAccountRequestModal />
        </>
    );
};

export default UnauthModals;
