// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shopify-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F0F0F0;
    padding: 10px 20px;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    height: 50px;
}

.header-right {
    display: flex;
    align-items: center;
}

.navbar-container {
    display: flex;
    margin-right: 20px;
}

.nav-item {
    margin-left: 10px;
}

.nav-icon {
    width: 30px;
    height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/headers/shopify_header/ShopifyHeader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".shopify-header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    background-color: #F0F0F0;\n    padding: 10px 20px;\n}\n\n.logo-container {\n    display: flex;\n    align-items: center;\n}\n\n.logo {\n    height: 50px;\n}\n\n.header-right {\n    display: flex;\n    align-items: center;\n}\n\n.navbar-container {\n    display: flex;\n    margin-right: 20px;\n}\n\n.nav-item {\n    margin-left: 10px;\n}\n\n.nav-icon {\n    width: 30px;\n    height: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
