// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* ProductTitle.css */
.speed-swabs-product-details-product-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1.2;
    text-align: left;
    color: #706F70;
}

.highlight {
    color: #007BFF;
}
`, "",{"version":3,"sources":["webpack://./src/components/shopify/product/details/product_title/ProductTitle.css"],"names":[],"mappings":";AACA,qBAAqB;AACrB;IACI,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["\n/* ProductTitle.css */\n.speed-swabs-product-details-product-title {\n    font-size: 2.5rem;\n    font-weight: bold;\n    margin-bottom: 10px;\n    line-height: 1.2;\n    text-align: left;\n    color: #706F70;\n}\n\n.highlight {\n    color: #007BFF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
