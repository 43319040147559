// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.speed-swabs-footer {
    background-color: #4F4F4F;
    text-align: center;
    padding: 15px 0;
    width: 100%;
    position: relative; /* Let the footer naturally fall at the bottom of the page */
    font-size: 14px;
    font-weight: bold;
    z-index: 9999; /* Ensure it appears above other content */
    margin-top: 50px; /* Add margin to provide spacing above the footer */
}

.speed-swabs-footer-text {
    color: #ffffff;
    text-decoration: none;
    margin: 0;
}

/* For mobile devices */
@media (max-width: 576px) {
    .speed-swabs-footer {
        font-size: 12px;
        padding: 10px 0;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/footers/speed_swab_footer/SpeedSwabsFooter.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,kBAAkB,EAAE,4DAA4D;IAChF,eAAe;IACf,iBAAiB;IACjB,aAAa,EAAE,0CAA0C;IACzD,gBAAgB,EAAE,mDAAmD;AACzE;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,SAAS;AACb;;AAEA,uBAAuB;AACvB;IACI;QACI,eAAe;QACf,eAAe;IACnB;AACJ","sourcesContent":[".speed-swabs-footer {\n    background-color: #4F4F4F;\n    text-align: center;\n    padding: 15px 0;\n    width: 100%;\n    position: relative; /* Let the footer naturally fall at the bottom of the page */\n    font-size: 14px;\n    font-weight: bold;\n    z-index: 9999; /* Ensure it appears above other content */\n    margin-top: 50px; /* Add margin to provide spacing above the footer */\n}\n\n.speed-swabs-footer-text {\n    color: #ffffff;\n    text-decoration: none;\n    margin: 0;\n}\n\n/* For mobile devices */\n@media (max-width: 576px) {\n    .speed-swabs-footer {\n        font-size: 12px;\n        padding: 10px 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
