// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* QuestEspManagementModal.css */

.esp-management-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.esp-management-modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

.esp-management-modal h2 {
    margin-top: 0;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.collector-selection,
.account-number-selection,
.account-type-selection {
    margin-bottom: 20px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.account-toggle {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/quest_esp_management/QuestEspManagementModal.css"],"names":[],"mappings":"AAAA,gCAAgC;;AAEhC;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,wCAAwC;IACxC,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;AAEA;;;IAGI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;AACpB","sourcesContent":["/* QuestEspManagementModal.css */\n\n.esp-management-modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n}\n\n.esp-management-modal {\n    background-color: #fff;\n    padding: 20px;\n    border-radius: 8px;\n    width: 400px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n    position: relative;\n}\n\n.esp-management-modal h2 {\n    margin-top: 0;\n}\n\n.close-button {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    background: none;\n    border: none;\n    font-size: 16px;\n    cursor: pointer;\n}\n\n.collector-selection,\n.account-number-selection,\n.account-type-selection {\n    margin-bottom: 20px;\n}\n\nlabel {\n    display: block;\n    font-weight: bold;\n    margin-bottom: 5px;\n}\n\n.account-toggle {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
