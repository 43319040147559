import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


export const requestEspCollectorAccess = createAsyncThunk(
    'questEspManagement/requestEspCollectorAccess',
    async (thunkAPI) => {
        const accessToken = sessionStorage.getItem("accessToken");
        // return await fetchOrders(accessToken);
        return null
    }
);

const questEspManagementSlice = createSlice({
    name: 'questEspManagement',
    initialState: {
        isQuestEspManagementVisible: false,
        questEspRequests: [],
        selectedOrganization: null,
        status: 'idle',
        error: null,
    },
    reducers: {
        // Clears the quest orders data
        clearQuestEspManagementData: (state) => {
            state.questEspRequests = [];
        },
        // Toggles the visibility of the quest order modal
        toggleQuestEspManagementModal: (state) => {
            state.isQuestEspManagementVisible = !state.isQuestEspManagementVisible;
        },
        // Sets the selected organization data for display in the modal
        setCollectorSelectedOrganization: (state, action) => {
            state.selectedOrganization = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(requestEspCollectorAccess.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(requestEspCollectorAccess.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.questEspRequests = action.payload || [];
            })
            .addCase(requestEspCollectorAccess.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

// Export the actions to be used in components
export const { toggleQuestEspManagementModal, setCollectorSelectedOrganization, clearQuestEspManagementData } = questEspManagementSlice.actions;

export default questEspManagementSlice.reducer;