// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* CSS (NavBubble.css) */
.nav-bubble-container {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
}

.nav-bubble {
    display: inline-flex;
    background: #707070;
    color: #fff;
    padding: 3px 7px;
    border-radius: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.bubble-button {
    background: none;
    border: none;
    padding: 8px 15px;
    font-size: 12px; /* Reduced size */
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.bubble-button.active {
    background-color: #0073e6;
    color: white;
    border-radius: 15px;
    transform: scale(1.05); /* Slight scaling */
}

.bubble-button:hover {
    transform: scale(1.03);
}

.bubble-content {
    margin-top: 10px;
}


@media (max-width: 1200px) {
    .nav-bubble{
        margin-top: 10%;
    }
}

@media (max-width: 992px) {
    .nav-bubble{
        margin-top: 10%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/modals/nav_bubble/NavBubble.css"],"names":[],"mappings":";AACA,wBAAwB;AACxB;IACI,eAAe;IACf,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,WAAW;AACf;;AAEA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,yCAAyC;AAC7C;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,eAAe,EAAE,iBAAiB;IAClC,eAAe;IACf,2DAA2D;AAC/D;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,sBAAsB,EAAE,mBAAmB;AAC/C;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB;;;AAGA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":["\n/* CSS (NavBubble.css) */\n.nav-bubble-container {\n    position: fixed;\n    top: 10px;\n    left: 50%;\n    transform: translateX(-50%);\n    z-index: 10;\n}\n\n.nav-bubble {\n    display: inline-flex;\n    background: #707070;\n    color: #fff;\n    padding: 3px 7px;\n    border-radius: 30px;\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);\n}\n\n.bubble-button {\n    background: none;\n    border: none;\n    padding: 8px 15px;\n    font-size: 12px; /* Reduced size */\n    cursor: pointer;\n    transition: transform 0.3s ease, background-color 0.3s ease;\n}\n\n.bubble-button.active {\n    background-color: #0073e6;\n    color: white;\n    border-radius: 15px;\n    transform: scale(1.05); /* Slight scaling */\n}\n\n.bubble-button:hover {\n    transform: scale(1.03);\n}\n\n.bubble-content {\n    margin-top: 10px;\n}\n\n\n@media (max-width: 1200px) {\n    .nav-bubble{\n        margin-top: 10%;\n    }\n}\n\n@media (max-width: 992px) {\n    .nav-bubble{\n        margin-top: 10%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
